import React from 'react';

import './Input.css';

const InnerInput = ({ message, setMessage, sendMessage}) => (
    <>
    <form className="form">
        <div className="inner_input_div">
        <div>
            <input
                className="Inner_input"
                type="text"
                placeholder="Enter your info-text here"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                onKeyPress={event => event.key === 'Enter' ? sendMessage(event) : null}
            />
        </div>
        
        </div>
    </form>
    </>
)

export default InnerInput;