import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Join from './components/Join/Join';
import Chat from './components/Chat/Chat';
import ChatUserTest from './components/ChatUserTest/ChatUserTest';
import Dashboard from './components/BackendDashboard/Dashboard';


const App = () => {
	return (
		<Router>
			<Route path="/" exact component={Join} />
			<Route path="/chat" exact component={Chat} />
			<Route path="/chat-test" exact component={ChatUserTest} />
			{/* for backend */}
			<Route path="/dashboard" exact component={Dashboard} /> 
		</Router>
	);
}

export default App;
