import React, { useState, useEffect } from "react";
import queryString from "query-string"; // use to retrieve data from url
import io from "socket.io-client";
import "./Dashboard.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import TextContainer from "../TextContainer/TextContainer";

import Messages from "../Messages/Messages";
import { useRef } from "react";

import InfoBar from "../InfoBar/InfoBar";
import Input from "../Input/Input";
import InnerInput from "../Input/innerInput";
let socket;

const Dashboard = ({ location }) => {
  // location comes from router.js
  const [startDate, setStartDate] = useState(new Date());
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [group_password, setGroupPassword] = useState("");
  const [users, setUsers] = useState("");
  const [message, setMessage] = useState(""); // single message store
  const [infoTextMessage, setInfoTextMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [messageQueue, setMessageQueue] = useState([]);
  const typingTimeoutRef = useRef(null);
  const [visibleTicks, setvisibleTicks] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [city, setCity] = useState("");
  const [job, setJob] = useState("");
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState("");
  const [firstContact, setFirstContact] = useState("");
  const [moreDetails, setMoreDetails] = useState("");

  const ENDPOINT = "http://188.166.176.163:5000/"; // to make server connection

  useEffect(() => {
    const { name, room } = queryString.parse(location.search); // used to get name and room from join page

    socket = io(ENDPOINT);

    setName(name);
    setRoom(room);
    socket.emit("join", { name, room }, () => {}); // to send msg to server

    return () => {
      // to unmount
      socket.emit("disconnect");
      socket.off();
    };
  }, [ENDPOINT, location.search]);

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages([...messages, message]); // add messages
    });
    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
  }, [messages]);

  const sendMessage = (event) => {
    event.preventDefault();

    if (message) {
      socket.emit("sendMessage", message, () => setMessage(""));
    }
  };

  const handleInput = (event) => {
    const inputField = event.target;
    const inputId = inputField.id;
    const inputValue = inputField.value;

    if (inputId === "inputFirstName") setFirstName(inputValue);
    else if (inputId === "inputdob") setDateOfBirth(inputValue);
    else if (inputId === "inputCity") setCity(inputValue);
    else if (inputId === "inputJob") setJob(inputValue);
    else if (inputId === "inputStatus") setStatus(inputValue);
    else if (inputId === "inputProgress") setProgress(inputValue);
    else if (inputId === "inputFirstContact") setFirstContact(inputValue);
    else if (inputId === "inputMoreDetails") setMoreDetails(inputValue);

    setvisibleTicks((prevVisibleTicks) =>
      prevVisibleTicks.filter((id) => id !== inputField.id)
    );
  };

  const handleBlur = (event) => {
    const inputField = event.target;
    setTimeout(() => {
      showGreenTick(inputField.id);
    }, 1000);
  };

  const showGreenTick = (inputId) => {
    let isNotEmpty = false;

    if (inputId === "inputFirstName") isNotEmpty = firstName.trim() !== "";
    else if (inputId === "inputdob") isNotEmpty = dateOfBirth.trim() !== "";
    else if (inputId === "inputCity") isNotEmpty = city.trim() !== "";
    else if (inputId === "inputJob") isNotEmpty = job.trim() !== "";
    else if (inputId === "inputStatus") isNotEmpty = status.trim() !== "";
    else if (inputId === "inputProgress") isNotEmpty = progress.trim() !== "";
    else if (inputId === "inputFirstContact")
      isNotEmpty = firstContact.trim() !== "";
    else if (inputId === "inputMoreDetails")
      isNotEmpty = moreDetails.trim() !== "";

    if (isNotEmpty) {
      setvisibleTicks((prevVisibleTicks) => {
        if (!prevVisibleTicks.includes(inputId)) {
          return [...prevVisibleTicks, inputId];
        }
        return prevVisibleTicks;
      });
    }
  };

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  console.log(message, messages);

  return (
    <>
      <div className="outerContainer">
        <div
          className="top_bar"
          style={{ backgroundColor: "gray", height: "40px" }}
        >
          <div className="top_bar_content">
            <span
              style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}
            >
              DIALOGUE CONTROL CENTER | CLIENT MANAGEMENT | MESSAGE TO NEW
              CLIENT | TEAM MANAGEMENT | ACCOUNT MANAGEMENT
            </span>
          </div>
        </div>
        <div
          className="background-wrapper"
          style={{ backgroundColor: "#d3d3d3", position: "absolute" }}
        >
          <div
            className="top_bar_secondary"
            style={{ backgroundColor: "#999999", height: "60px" }}
          >
            <div className="top_bar_content">
              <span
                style={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginLeft: "40px",
                }}
              >
                GROSS INCOME (TOTAL):{" "}
                <span style={{ color: "#90ee90" }}>123$</span>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp; FEEs (TOTAL): &nbsp;
                <span style={{ color: "#90ee90" }}>45$</span>
                &nbsp;&nbsp;&nbsp;&nbsp; AFTER FEEs (TOTAL): &nbsp;
                <span style={{ color: "#90ee90" }}>76$</span>
                &nbsp;&nbsp;&nbsp;&nbsp; TAXES (TOTAL): &nbsp;
                <span style={{ color: "#90ee90" }}>98$</span>
                &nbsp;&nbsp;&nbsp;&nbsp; NET (TOTAL): &nbsp;
                <span style={{ color: "#90ee90" }}>123$</span>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </span>
            </div>
          </div>
        </div>

        <div
          className="top_bar_secondary_content"
          style={{ backgroundColor: "#d3d3d3", height: "230px" }}
        >
          <div className="top_bar_contents">
            <span
              style={{ color: "white", fontSize: "20px", fontWeight: "bold"}}
            >
            </span>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS ONLINE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span  className="client-detai-text">WAITING ROOM:</span>
            <span style={{color:"orange"}} className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">Outs:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS ACTIVE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS INACTIVE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS DEAD:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">TOTAL CLIENTS (LT):</span>
            <span className="client-detai-text">5</span>
            </div>
          </div>

          <div className="top_bar_contents">
            <span
              style={{ color: "white", fontSize: "20px", fontWeight: "bold"}}
            >
            </span>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS ONLINE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span  className="client-detai-text">WAITING ROOM:</span>
            <span style={{color:"orange"}} className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">Outs:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS ACTIVE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS INACTIVE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS DEAD:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">TOTAL CLIENTS (LT):</span>
            <span className="client-detai-text">5</span>
            </div>
          </div>

          <div className="top_bar_contents">
            <span
              style={{ color: "white", fontSize: "20px", fontWeight: "bold"}}
            >
            </span>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS ONLINE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span  className="client-detai-text">WAITING ROOM:</span>
            <span style={{color:"orange"}} className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">Outs:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS ACTIVE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS INACTIVE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS DEAD:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">TOTAL CLIENTS (LT):</span>
            <span className="client-detai-text">5</span>
            </div>
          </div>

          <div className="top_bar_contents">
            <span
              style={{ color: "white", fontSize: "20px", fontWeight: "bold"}}
            >
            </span>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS ONLINE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span  className="client-detai-text">WAITING ROOM:</span>
            <span style={{color:"orange"}} className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">Outs:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS ACTIVE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS INACTIVE:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">CLIENTS DEAD:</span>
            <span className="client-detai-text">5</span>
            </div>
            <div className="client-detail-section">
            <span className="client-detai-text">TOTAL CLIENTS (LT):</span>
            <span className="client-detai-text">5</span>
            </div>
          </div>
        </div>
        <div className="client_details_history">
            <span style={{display:"flex", justifyContent:"center", fontSize:"18px", paddingTop:"10px", fontWeight:"bold"}}>Client Pool</span>
          </div>
        {/* <div className="profile_details">
          <div className="upper_half">
            <span className="more_details">Client Profile</span>
            <div className="dflex">
              <span className="coins">Coins &gt;</span>
              <span className="coins">Out &gt;</span>
            </div>
            <span className="first_name">
              First Name &gt;
              <input className="profile_detail_input" type="text" />
            </span>
            <span className="first_name">
              Date of Birth &gt;
              <input className="profile_detail_input" type="text" />
            </span>
            <span className="first_name">
              City &gt;
              <input className="profile_detail_input" type="text" />
            </span>
            <span className="first_name">
              Job &gt;
              <input className="profile_detail_input" type="text" />
            </span>
            <span className="first_name">
              Status &gt;
              <input className="profile_detail_input" type="text" />
            </span>
            <span className="first_name">
              Progress &gt;
              <input className="profile_detail_input" type="text" />
            </span>
            <span className="first_name">
              First Contact &gt;
              <input className="profile_detail_input" type="text" />
            </span>
          </div>
          <div className="lower_half">
            <span className="more_details">More Details</span>
            <input
              className="more_details_input"
              placeholder="here we enter more text about the user"
              type="text"
            ></input>
          </div>
        </div> */}

      </div>
    </>
  );
};

export default Dashboard;
