import React, { useState, useEffect } from 'react';
import queryString from 'query-string'; // use to retrieve data from url
import io from 'socket.io-client';
import './Chat.css';
import TextContainer from '../TextContainer/TextContainer';

import Messages from '../Messages/Messages';
import { useRef } from 'react';

import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';
import InnerInput from '../Input/innerInput';
let socket;

const Chat = ({ location }) => { // location comes from router.js

    const [name, setName] = useState('');
    const [room, setRoom] = useState('');
    const [group_password, setGroupPassword] = useState('');
    const [users, setUsers] = useState('');
    const [message, setMessage] = useState(''); // single message store
    const [infoTextMessage, setInfoTextMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [messageQueue, setMessageQueue] = useState([]);
    const typingTimeoutRef = useRef(null);
    const [visibleTicks, setvisibleTicks] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [city, setCity] = useState('');
    const [job, setJob] = useState('');
    const [status, setStatus] = useState('');
    const [progress, setProgress] = useState('');
    const [firstContact, setFirstContact] = useState('');
    const [moreDetails, setMoreDetails] = useState('');


    const ENDPOINT = 'http://188.166.176.163:5000/'; // to make server connection

    useEffect(() => {
        const { name, room } = queryString.parse(location.search); // used to get name and room from join page
        //console.log(location.search);
        //console.log(data);
        //console.log(name, room);
        socket = io(ENDPOINT);

        setName(name);
        setRoom(room);
        // console.log(socket);
        socket.emit('join', { name, room }, () => {
            // alert(error);
        }); // to send msg to server

        return () => { // to unmount
            socket.emit('disconnect');
            socket.off();
        }

    }, [ENDPOINT, location.search]);


    // useEffect(() => {
    //     const { name, group_password } = queryString.parse(location.search);
    //     socket.on("handleMessageDistribution", ({ message, group_code }) => {
    //         if (group_code === group_password) {
    //             setMessageQueue((prevMessageQueue) => [...prevMessageQueue, message]);
    //         }
    //     });

    //     return () => {
    //         socket.off("handleMessageDistribution");
    //     };
    // }, []);

    //handling message
    useEffect(() => {
        socket.on('message', (message) => {
            setMessages([...messages, message]) // add messages
        });
        socket.on("roomData", ({ users }) => {
            setUsers(users);
        });
    }, [messages]);

    const sendMessage = (event) => {
        event.preventDefault();

        if (message) {
            socket.emit('sendMessage', message, () => setMessage(''));
        }
    }

    const handleInput = (event) => {
        const inputField = event.target;
        const inputId = inputField.id;
        const inputValue = inputField.value;

        if (inputId === 'inputFirstName') setFirstName(inputValue);
        else if (inputId === 'inputdob') setDateOfBirth(inputValue);
        else if (inputId === 'inputCity') setCity(inputValue);
        else if (inputId === 'inputJob') setJob(inputValue);
        else if (inputId === 'inputStatus') setStatus(inputValue);
        else if (inputId === 'inputProgress') setProgress(inputValue);
        else if (inputId === 'inputFirstContact') setFirstContact(inputValue);
        else if (inputId === 'inputMoreDetails') setMoreDetails(inputValue);


        setvisibleTicks((prevVisibleTicks) =>
            prevVisibleTicks.filter((id) => id !== inputField.id)
        );
    };



    const handleBlur = (event) => {
        const inputField = event.target;
        setTimeout(() => {
            showGreenTick(inputField.id);
        }, 1000);
    };


    const showGreenTick = (inputId) => {
        let isNotEmpty = false;

        if (inputId === 'inputFirstName') isNotEmpty = firstName.trim() !== '';
        else if (inputId === 'inputdob') isNotEmpty = dateOfBirth.trim() !== '';
        else if (inputId === 'inputCity') isNotEmpty = city.trim() !== '';
        else if (inputId === 'inputJob') isNotEmpty = job.trim() !== '';
        else if (inputId === 'inputStatus') isNotEmpty = status.trim() !== '';
        else if (inputId === 'inputProgress') isNotEmpty = progress.trim() !== '';
        else if (inputId === 'inputFirstContact') isNotEmpty = firstContact.trim() !== '';
        else if (inputId === 'inputMoreDetails') isNotEmpty = moreDetails.trim() !== '';


        if (isNotEmpty) {
            setvisibleTicks((prevVisibleTicks) => {
                if (!prevVisibleTicks.includes(inputId)) {
                    return [...prevVisibleTicks, inputId];
                }
                return prevVisibleTicks;
            });
        }
    };




    useEffect(() => {
        return () => {
            if (typingTimeoutRef.current) {
                clearTimeout(typingTimeoutRef.current);
            }
        };
    }, []);

    // const sendInfoTextMessage = (event) => {
    //     event.preventDefault();

    //     if (message) {
    //         socket.emit('messageInfoText', { message: { user: { id: socket.id, name }, text: message }, group_password }, () => setInfoTextMessage(''));
    //     }
    // };

    // const handleEndChat = () => {
    //     if (messageQueue.length > 0) {
    //       // Remove the current end user from the message queue
    //       setMessageQueue((prevMessageQueue) => prevMessageQueue.slice(1));
    //     }

    //     // Mark the agent as available if the agent's queue length is less than 5
    //     if (messageQueue.length - 1 < 5) {
    //       const agentId = agentId ;
    //       socket.emit("setAgentAvailability", { agentId, available: true });
    //     }
    //   };




    console.log(message, messages);

    //function for sending messages
    return (
        <>

            <div className="outerContainer">
                <div className="top_bar" style={{ backgroundColor: "gray" }}>
                    {/* <div className="top_bar_content">
                        <span >Logout:</span>
                    </div> */}
                </div>

                <div className="profile_details">
                    <div className="upper_half">
                        <span className="more_details">
                            Client Profile
                        </span>
                        <div className='dflex'>
                            <span className="coins">
                                Coins  &gt;
                            </span>
                            <span className="coins">
                                Out  &gt;
                            </span>
                        </div>
                        <span className="first_name">
                            First Name  &gt;
                            <input className="profile_detail_input" type="text" />
                        </span>
                        <span className="first_name">
                            Date of Birth  &gt;
                            <input className="profile_detail_input" type="text" />
                        </span>
                        <span className="first_name">
                            City  &gt;
                            <input className="profile_detail_input" type="text" />
                        </span>
                        <span className="first_name">
                            Job  &gt;
                            <input className="profile_detail_input" type="text" />
                        </span>
                        <span className="first_name">
                            Status  &gt;
                            <input className="profile_detail_input" type="text" />
                        </span>
                        <span className="first_name">
                            Progress  &gt;
                            <input className="profile_detail_input" type="text" />
                        </span>
                        <span className="first_name">
                            First Contact  &gt;
                            <input className="profile_detail_input" type="text" />
                        </span>
                    </div>
                    <div className="lower_half">
                        <span className="more_details">
                            More Details
                        </span>
                        <input className='more_details_input' placeholder='here we enter more text about the user' type='text'></input>
                    </div>
                </div>
                <div className="container">
                    {/* <InfoBar room={room} /> */}
                    <Messages messages={messages} name={name} />
                    <Input message={message} sendMessage={sendMessage} setMessage={setMessage} />
                    <div className="inner_input">
                        <InnerInput message={infoTextMessage} sendMessage={sendMessage} setMessage={setInfoTextMessage} />
                    </div>
                </div>
                {/* <TextContainer users={users} /> */}
                <div className="profile_details">
                    <div className="upper_half">
                        <span className="more_details">
                            EP Profile
                        </span>
                        <div className='dflex'>
                            <span className="coins">
                                Coins  &gt;
                            </span>
                            <span className="coins">
                                Out  &gt;
                            </span>
                        </div>
                        <span className="first_name">
                            First Name &gt;
                            <input
                                id="inputFirstName"
                                className="profile_detail_input"
                                type="text"
                                value={firstName}
                                onChange={(event) => setFirstName(event.target.value)}
                                onInput={(event) => handleInput(event)}
                                onBlur={(event) => handleBlur(event)}
                            />
                            {visibleTicks.includes('inputFirstName') && (
                                <span className="green-tick">✓</span>
                            )}
                        </span>

                        <span className="first_name">
                            Date of Birth  &gt;
                            <input
                                id="inputdob"
                                className="profile_detail_input"
                                type="text"
                                value={dateOfBirth}
                                onChange={(event) => setDateOfBirth(event.target.value)}
                                onInput={(event) => handleInput(event)}
                                onBlur={(event) => handleBlur(event)}
                            />
                            {visibleTicks.includes('inputdob') && (
                                <span className="green-tick">✓</span>
                            )}

                        </span>
                        <span className="first_name">
                            City  &gt;
                            <input
                                id="inputCity"
                                className="profile_detail_input"
                                type="text"
                                value={city}
                                onChange={(event) => setCity(event.target.value)}
                                onInput={(event) => handleInput(event)}
                                onBlur={(event) => handleBlur(event)}
                            />
                            {visibleTicks.includes('inputCity') && (
                                <span className="green-tick">✓</span>
                            )}

                        </span>
                        <span className="first_name">
                            Job  &gt;
                            <input
                                id="inputJob"
                                className="profile_detail_input"
                                type="text"
                                value={job}
                                onChange={(event) => setJob(event.target.value)}
                                onInput={(event) => handleInput(event)}
                                onBlur={(event) => handleBlur(event)}
                            />
                            {visibleTicks.includes('inputJob') && (
                                <span className="green-tick">✓</span>
                            )}

                        </span>
                        <span className="first_name">
                            Status  &gt;
                            <input
                                id="inputStatus"
                                className="profile_detail_input"
                                type="text"
                                value={status}
                                onChange={(event) => setStatus(event.target.value)}
                                onInput={(event) => handleInput(event)}
                                onBlur={(event) => handleBlur(event)}
                            />
                            {visibleTicks.includes('inputStatus') && (
                                <span className="green-tick">✓</span>
                            )}

                        </span>
                        <span className="first_name">
                            Progress  &gt;
                            <input
                                id="inputProgress"
                                className="profile_detail_input"
                                type="text"
                                value={progress}
                                onChange={(event) => setProgress(event.target.value)}
                                onInput={(event) => handleInput(event)}
                                onBlur={(event) => handleBlur(event)}
                            />
                            {visibleTicks.includes('inputProgress') && (
                                <span className="green-tick">✓</span>
                            )}

                        </span>
                        <span className="first_name">
                            First Contact  &gt;
                            <input
                                id="inputFirstContact"
                                className="profile_detail_input"
                                type="text"
                                value={firstContact}
                                onChange={(event) => setFirstContact(event.target.value)}
                                onInput={(event) => handleInput(event)}
                                onBlur={(event) => handleBlur(event)}
                            />
                            {visibleTicks.includes('inputFirstContact') && (
                                <span className="green-tick">✓</span>
                            )}

                        </span>
                    </div>
                    <div className="lower_half">
                        <span className="more_details">
                            More Details
                        </span>
                        <input
                            id="inputMoreDetails"
                            className="more_details_input"
                            placeholder="here we enter more text about the user"
                            type="text"
                            value={moreDetails}
                            onChange={(event) => setMoreDetails(event.target.value)}
                            onInput={(event) => handleInput(event)}
                            onBlur={(event) => handleBlur(event)}
                        />
                        {visibleTicks.includes('inputMoreDetails') && (
                            <span className="green-tick">✓</span>
                        )}
                    </div>

                </div>
            </div>
        </>
    )
};

export default Chat;
