import React from 'react';
import './InputTest.css';
import { useState } from 'react';

const Input = ({ message, setMessage, sendMessage, endChat }) => {
  const [room, setRoom] = useState('');

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (message.trim()) {
      sendMessage(event, room, message);
      setMessage(''); // Clear the message input after sending
    }
  };

  return (
    <>
      <form className="form" onSubmit={handleSendMessage}>
        <input
          className="input"
          type="text"
          placeholder="Enter Group password"
          value={room}
          onChange={(event) => setRoom(event.target.value)}
        />
        <input
          className="input"
          type="text"
          placeholder="Enter your message text here"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          onKeyPress={(event) =>
            event.key === 'Enter' ? handleSendMessage(event) : null
          }
        />
      </form>
      <div className="sendButtonDivTest">
        {/* <button className="sendButton" onClick={e => endChat(e)}>SEND AND NEXT ?</button> */}
        <button className="sendButton button2" onClick={handleSendMessage}>
          SEND
        </button>
      </div>
    </>
  );
};

export default Input;
