import React from 'react';

import './Input.css';

const Input = ({ message, setMessage, sendMessage, endChat}) => (
    <>
    <form className="form">
        <input
            className="input"
            type="text"
            placeholder="Enter your message text here"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            onKeyPress={event => event.key === 'Enter' ? sendMessage(event) : null}
        />
        {/* <button className="sendButton" onClick={e => sendMessage(e)}>Send</button> */}
    </form>
    <div className="sendButtonDiv">
        <button className="sendButton" onClick={e => endChat(e)}>SEND AND NEXT ?</button>
        <button className="sendButton button2" onClick={e => sendMessage(e)}>SEND AND RESEND</button>
    </div>
    </>
)

export default Input;