import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // use to link with chat.js
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import './Join.css';

const Join = () => {
    const [name, setName] = useState('');
    const [room, setRoom] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    const handleLogin = async (event) => {
        event.preventDefault();

        if (!name || !room || !password) {
          return;
        }

        try {
          const response = await axios.post('http://188.166.176.163:5000/login', { name, password, group_password: room });
          console.log(response);
          if (response.status === 200) {
            const agentId = response.data.user.id;
            // setLoggedInAgentId(agentId);
            // history.push(`./chat?name=${name}&group_password=${room}&agentId=${agentId}`);
            history.push(`./chat?name=${name}&room=${room}`);

          } else {
            // Handle login errors
            alert('Login failed. Please check your credentials and try again.');
          }
        } catch (error) {
          // Handle request errors
          alert('An error occurred during login. Please try again later.');
        }
      };

    return (
        <div className="joinOuterContainer">
            <div className="joinInnerContainer">
                <h1 className="heading">Welcome to Zvw chat app</h1>
                <div><input placeholder="Username" className="joinInput" type="text" onChange={(event) => setName(event.target.value)} /></div>
                <div><input placeholder="Password" className="joinInput mt-20" type="password" onChange={(event) => setPassword(event.target.value)} /></div>
                <div><input placeholder="Group Code" className="joinInput mt-20" type="text" onChange={(event) => setRoom(event.target.value)} /></div>
                {/* <Link onClick={event => (!name || !room) ? event.preventDefault() : null} to={`./chat?name=${name}&room=${room}`}> */}
                <button className="button mt-20" type="submit" onClick={handleLogin}>Sign In</button>
                {/* </Link>                */}
            </div>
        </div>
    )
};

export default Join;
// Link is to transfer from join to chat onsubmit
